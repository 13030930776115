.CardNova {
  border-radius: 10px!important;
  transition: all 0.2s;
  border-bottom: 0!important;
}

.CardNova:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transform: scale(1.01);
}

.CardNova:nth-child(1) {
  border-bottom: 0!important;
  background-color: yellow;
}
